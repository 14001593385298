import React, { useState } from 'react';
import { FaWineGlassAlt, FaCalendarAlt, FaCloudSun, FaMapMarkerAlt, FaBed, FaInfoCircle, FaBars, FaTshirt, FaSun } from 'react-icons/fa';

const App = () => {
  const [activeWinery, setActiveWinery] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const weather = [
    { date: '2024-07-04', temp: 85, icon: '☀️', description: 'Sunny', uv: 'High', clothing: 'Light, breathable clothing and sun protection' },
    { date: '2024-07-05', temp: 82, icon: '⛅', description: 'Partly Cloudy', uv: 'Moderate', clothing: 'Light layers and sun protection' },
    { date: '2024-07-06', temp: 80, icon: '☁️', description: 'Cloudy', uv: 'Low', clothing: 'Light layers' },
    { date: '2024-07-07', temp: 83, icon: '☀️', description: 'Sunny', uv: 'High', clothing: 'Light, breathable clothing and sun protection' },
  ];

  const calendarUrl = "https://calendar.google.com/calendar/embed?src=cf1565ad96a44dfd96f0bec7048301d432707b1541cbedd4c74d94eac99a0d80%40group.calendar.google.com&ctz=America%2FLos_Angeles";

  const wineries = [
    // ... (keep the wineries data as before)
  ];

  const sections = [
    { id: "about", title: "About", icon: FaInfoCircle },
    { id: "wineries", title: "Wineries", icon: FaWineGlassAlt },
    { id: "trip-details", title: "Trip Details", icon: FaCalendarAlt },
    { id: "weather", title: "Weather", icon: FaCloudSun },
    { id: "calendar", title: "Calendar", icon: FaCalendarAlt },
  ];

  return (
    <div className="flex flex-col md:flex-row">
      {/* Mobile Menu Button */}
      <button 
        className="md:hidden fixed top-4 right-4 z-50 bg-red-600 text-white p-2 rounded"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <FaBars />
      </button>

      {/* Sidebar */}
      <div className={`w-full md:w-64 bg-gray-100 p-6 fixed h-screen z-40 transition-all duration-300 ease-in-out ${isMobileMenuOpen ? 'left-0' : '-left-full'} md:left-0`}>
        <h1 className="text-2xl font-bold mb-6 text-gray-900 flex items-center">
          <FaWineGlassAlt className="mr-2 text-red-600" />
          Paso Robles Trip
        </h1>
        <nav>
          {sections.map((section) => (
            <a 
              key={section.id} 
              href={`#${section.id}`} 
              className="block mb-4 text-gray-700 hover:text-red-600 flex items-center"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <section.icon className="mr-2" />
              {section.title}
            </a>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="w-full md:ml-64 p-4 md:p-8">
        <section id="about" className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-gray-800">About Paso Robles Wine</h2>
          <p className="text-gray-600 mb-4">Paso Robles, located halfway between San Francisco and Los Angeles, is California's fastest growing wine region and largest geographic appellation. With a greater day-to-night temperature swing than any other appellation in California, distinct microclimates, diverse soils and a long growing season, Paso Robles is a unique wine region blessed with optimal growing conditions for producing premium and ultra-premium wines.</p>
          <p className="text-gray-600 mb-4">Key facts about Paso Robles wine:</p>
          <ul className="list-disc pl-5 mb-4 text-gray-600">
            <li>Over 40,000 vineyard acres and 200 wineries</li>
            <li>60+ wine grape varieties produced</li>
            <li>First vines were planted in 1797 by Franciscan missionaries</li>
            <li>Became an official American Viticultural Area (AVA) in 1983</li>
            <li>Known for its heritage varietal Zinfandel, Cabernet Sauvignon, and Rhône-style wines</li>
          </ul>
          <p className="text-gray-600 mb-4">Popular wine styles and grapes in Paso Robles include:</p>
          <ul className="list-disc pl-5 mb-4 text-gray-600">
            <li>Cabernet Sauvignon: Full-bodied with rich black fruit flavors and firm tannins</li>
            <li>Zinfandel: Bold and spicy with jammy fruit characteristics, often featuring bramble and pepper notes</li>
            <li>Rhône-style blends: Often based on Syrah, Grenache, and Mourvèdre, known for their complexity and depth</li>
            <li>Bordeaux-style blends: Typically Cabernet Sauvignon-based with Merlot and other Bordeaux varieties, offering structure and aging potential</li>
            <li>Innovative blends: Paso Robles is known for pushing boundaries, often combining both Rhône and Bordeaux varieties</li>
            <li>White wines: While less common, excellent Chardonnay, Viognier, and Roussanne are also produced in the region</li>
          </ul>
          <p className="text-gray-600 mb-4">The unique terroir of Paso Robles, with its limestone-rich soils and significant diurnal temperature variations, contributes to wines with good natural acidity, intense flavor profiles, and the ability to express both power and elegance.</p>
          <img src="https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80" alt="Paso Robles Vineyard" className="w-full h-64 object-cover rounded-lg mb-4" />
        </section>

        <section id="wineries" className="mb-12">
          {/* ... (keep the wineries section as before) */}
        </section>

        <section id="trip-details" className="mb-12">
          {/* ... (keep the trip details section as before) */}
        </section>

        <section id="weather" className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-gray-800 flex items-center">
            <FaCloudSun className="mr-2 text-red-600" />
            Weather Forecast
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {weather.map((day, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded shadow">
                <p className="font-semibold">{new Date(day.date).toLocaleDateString()}</p>
                <p className="text-4xl">{day.icon}</p>
                <p>{day.temp}°F</p>
                <p>{day.description}</p>
                <p className="flex items-center mt-2"><FaSun className="mr-2" /> UV Index: {day.uv}</p>
                <p className="flex items-center mt-2"><FaTshirt className="mr-2" /> {day.clothing}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="calendar" className="mb-12">
          {/* ... (keep the calendar section as before) */}
        </section>
      </div>
    </div>
  );
};

export default App;
